<template>
  <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
    <div
      class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
    >
      <h2
        class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
      >
        {{ $t("app.recover_password") }}
      </h2>

      <div class="intro-x mt-8">
        <input
          v-model="value.username"
          type="text"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
          :placeholder="$t('app.username')"
        />
      </div>

      <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <VButton
          :label="$t('app.send')"
          class="btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          :is-loading="isLoading"
          @click="$emit('click:send', value)"
        />

        <VButton
          :label="$t('app.back')"
          class="btn-outline-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          @click="$emit('click:back')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:back", "click:send"],
  data() {
    return {
      value: {
        username: null
      }
    };
  }
};
</script>
