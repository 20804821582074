<template>
  <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
    <div
      class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
    >
      <h2
        class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
      >
        {{ $t("app.authenticate") }}
      </h2>

      <div class="intro-x mt-8">
        <VText
          v-model="code"
          data-test="code"
          input-class="mt-5 intro-x login__input form-control py-3 px-4 border-gray-300 block"
          :placeholder="$t('app.code')"
          @keyup.enter="onClickAuthenticate"
        />
      </div>

      <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <VButton
          :label="$t('app.send')"
          :is-loading="isLoading"
          class="btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          @click="onClickAuthenticate"
        />
      </div>

      <div
        class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
      >
        {{ $t("app.resend_2FA_code_msg") }}

        <span
          class="text-theme-1 dark:text-theme-10 cursor-pointer"
          @click="$emit('click:resend')"
        >
          {{ $t("app.resend_2FA_code") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
// Mixins
import FormMixin from "@/mixins/FormMixin";
// Components
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  mixins: [FormMixin],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:authenticate", "click:back", "click:resend"],
  setup(props, context) {
    // Data
    const code = ref("");

    // Methods
    const onClickAuthenticate = () => {
      context.emit("click:authenticate", code.value);
    };

    return {
      code,
      onClickAuthenticate
    };
  }
};
</script>
