<template>
  <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
    <div
      class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
    >
      <h2
        class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
      >
        {{ $t("app.sign_in") }}
      </h2>

      <div class="intro-x mt-8">
        <VText
          v-model="value.username"
          data-test="username"
          input-class="mt-5 intro-x login__input form-control py-3 px-4 border-gray-300 block"
          :placeholder="$t('app.username')"
          @keyup.enter="onClickLogin"
        />

        <VText
          v-model="value.password"
          data-test="password"
          type="password"
          input-class="mt-5 intro-x login__input form-control py-3 px-4 border-gray-300 block"
          :placeholder="$t('app.password')"
          @keyup.enter="onClickLogin"
        />
      </div>

      <div
        class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
      >
        <div class="flex items-center mr-auto">
          <input
            id="remember-me"
            v-model="remember_me"
            data-test="remember-me"
            type="checkbox"
            class="form-check-input border mr-2"
          />
          <label class="cursor-pointer select-none" for="remember-me">
            {{ $t("app.remember_me") }}
          </label>
        </div>
        <a class="cursor-pointer" @click="$emit('click:forgot-password')">
          {{ $t("app.forgot_password") }}
        </a>
      </div>

      <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <VButton
          :label="$t('app.log_in')"
          :is-loading="isLoading"
          class="btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          @click="onClickLogin"
        />
      </div>

      <div
        class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
      >
        {{ $t("app.by_signing_up") }}
        <br />
        <a
          class="cursor-pointer text-theme-1 dark:text-theme-10"
          @click="$router.push({ name: 'terms-and-conditions' })"
        >
          {{ $t("app.terms_and_conditions") }}
        </a>
        &amp;
        <a
          class="cursor-pointer text-theme-1 dark:text-theme-10"
          @click="$router.push({ name: 'privacy-policy' })"
        >
          {{ $t("app.privacy_policy") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
// Mixins
import FormMixin from "@/mixins/FormMixin";
// Components
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  mixins: [FormMixin],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:login", "click:forgot-password"],
  setup() {
    const value = ref({
      username: null,
      password: null
    });

    return { value };
  },
  computed: {
    remember_me: {
      get() {
        return this.$store.getters["user/remember_me"];
      },
      set(value) {
        if (!value) {
          localStorage.removeItem("user");
        }

        localStorage.setItem("remember_me", value);
        sessionStorage.setItem("remember_me", value);

        this.$store.dispatch("user/setRememberMe", value);
      }
    }
  },
  methods: {
    async onClickLogin() {
      if (this.v) {
        const isValid = await this.v.$validate();

        if (!isValid) {
          return;
        }
      }

      this.$emit("click:login", this.value);
    }
  }
};
</script>
