<template>
  <LoginView>
    <ForgotPasswordForm
      v-if="hasForgotPassword"
      :is-loading="isLoading"
      @click:send="onClickSendForgotPassword"
      @click:back="hasForgotPassword = !hasForgotPassword"
    />

    <TwoFactorAuthenticationForm
      v-if="has2FA"
      :is-loading="isLoading"
      @click:resend="onClickResend2FACode"
      @click:authenticate="onClickAuthenticate"
    />

    <LoginForm
      v-else
      :is-loading="isLoading"
      @click:login="onClickLogin"
      @click:forgot-password="hasForgotPassword = !hasForgotPassword"
    />
  </LoginView>
</template>

<script>
import { ref, inject, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import LoginForm from "@/views/general/login/LoginForm";
import TwoFactorAuthenticationForm from "@/views/general/login/TwoFactorAuthenticationForm";
import ForgotPasswordForm from "@/views/general/login/ForgotPasswordForm";
import LoginView from "@/views/general/LoginView";

export default {
  components: {
    LoginForm,
    TwoFactorAuthenticationForm,
    ForgotPasswordForm,
    LoginView
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // Inject
    const toast = inject("toast");

    // Data
    const has2FA = ref(false);
    const username = ref("");
    const password = ref("");
    const hasForgotPassword = ref(false);
    const isLoading = ref(false);

    // Computed
    const documentTitle = computed(() => t("app.logins"));
    const remember_me = computed(() => store.getters["user/remember_me"]);

    // Composables
    const { request, isSuccess } = useRequest();

    // Methods
    const onClickLogin = async data => {
      isLoading.value = true;

      const userData = getStorageData();
      const user = JSON.parse(userData);

      if (userData && user.username === data.username) {
        await tokenLogin(user);
      } else {
        await login(data);
      }

      isLoading.value = false;
    };

    const redirectHome = () => {
      router.replace(route?.query?.from ?? { name: "home" });
    };

    const login = async data => {
      const response = await store.dispatch("user/login", { data });

      const { text, sent_to } = response?.payload?.message ?? {};

      if (!isSuccess(response) && text === "Password expired.") {
        toast.showAPIMessage(response);
        router.push({
          name: "change-password",
          params: { username: data.username }
        });
        return;
      }

      if (isSuccess(response) && text === "Verify 2FA code.") {
        set2FA(data);

        const email = sent_to?.email;
        const mobile = sent_to?.mobile;

        if (email && mobile) {
          // eslint-disable-next-line
          toast.show(
            t("app.send_2FA_code_mobile_email", { email, mobile }),
            "Success"
          );
        }

        if (mobile) {
          toast.show(t("app.send_2FA_code_mobile", { mobile }), "Success");
        }

        if (email) {
          toast.show(t("app.send_2FA_code_email", { email }), "Success");
        }

        return;
      }

      if (!isSuccess(response) && text === "Invalid 2FA code.") {
        set2FA(data);
        return;
      }

      toast.showAPIMessage(response);

      redirectHome();
    };

    const set2FA = data => {
      username.value = data?.username;
      password.value = data?.password;
      has2FA.value = true;
    };

    const tokenLogin = async user => {
      const { username, api_token } = user ?? {};

      await store.dispatch("user/login", {
        data: { username, api_token },
        toast
      });

      if (!store.getters["user/token_verified"]) {
        localStorage.removeItem("user");
        sessionStorage.removeItem("user");
      }

      redirectHome();
    };

    const getStorageData = () => {
      return localStorage.getItem("user") || sessionStorage.getItem("user");
    };

    const onClickSendForgotPassword = async data => {
      isLoading.value = true;

      const response = await request({
        endpoint: "password.request-reset",
        data
      });

      if (isSuccess(response)) {
        sessionStorage.removeItem("user");
        localStorage.removeItem("user");

        router.push({
          name: "reset-password",
          params: { username: data.username }
        });
      }

      isLoading.value = false;
    };

    const onClickResend2FACode = async () => {
      const response = await request({
        endpoint: "platform.resend-2FA",
        data: { username: username.value },
        showToaster: false
      });

      if (isSuccess(response)) {
        const email = response?.payload?.message?.sent_to?.email;
        const mobile = response?.payload?.message?.sent_to?.mobile;

        if (email && mobile) {
          // eslint-disable-next-line
          toast.show(
            t("app.resend_2FA_code_mobile_email", { email, mobile }),
            "Success"
          );
          return;
        }

        if (email) {
          toast.show(t("app.resend_2FA_code_email", { email }), "Success");
          return;
        }

        if (mobile) {
          toast.show(t("app.resend_2FA_code_mobile", { mobile }), "Success");
          return;
        }
      }
    };

    const onClickAuthenticate = async authentication_code => {
      isLoading.value = true;

      await login({
        username: username.value,
        password: password.value,
        authentication_code
      });

      isLoading.value = false;
    };

    return {
      has2FA,
      documentTitle,
      remember_me,
      hasForgotPassword,
      isLoading,
      onClickLogin,
      onClickSendForgotPassword,
      onClickResend2FACode,
      onClickAuthenticate
    };
  }
};
</script>
